import React,{useEffect} from "react";
import Loader from "./Loader";

const Deeplink = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Attempt to open the Android app
            const appUrl = 'https://play.google.com/store/apps/details?id=com.righthands&pli=1'; // Replace with your custom URL scheme
            const appStoreUrl = 'https://play.google.com/store/apps/details?id=com.righthands&pli=1';

            // Try to open the app, fallback to the store
            window.location.href = appUrl;

            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 2000); // Delay before redirecting to the app store
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Attempt to open the iOS app
            const appUrl = 'https://apps.apple.com/in/app/right-hands/id1641289996'; // Replace with your custom URL scheme
            const appStoreUrl = 'https://apps.apple.com/in/app/right-hands/id1641289996';

            // Try to open the app, fallback to the store
            window.location.href = appUrl;

            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 2000); // Delay before redirecting to the app store
        } else {
            // Handle unknown device or desktop
            let voucher = params.get('voucher');
            if(voucher && voucher !== ''){
                window.location.href = process.env.REACT_APP_INVITE_URL+'/frequency/checkout/details?step=2&hours_count=4&professionals_count=1&is_cleaning_materials=true&frequency_id=6253b87b59af23d16f5a584a&voucher='+voucher;
          
            }else{
                window.location.href = process.env.REACT_APP_INVITE_URL;
            }
        }
        // setTimeout(() => {
        // }, 1000);
      }, [params]);
    return (
        <div>
            <Loader/>
        </div>
    )
};

export default Deeplink;
